@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.wrapper {
  background: vars.$blue2;
}

.container {
  padding-bottom: 72px;
  @media screen and (max-width: vars.$big) {
    padding-bottom: calc(72px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    padding-bottom: calc(72px / vars.$medium * 100vw);
  }
}

.logo {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  padding-top: 68px;
  align-self: start;
  position: relative;
  z-index: 99;
  @media screen and (max-width: vars.$big) {
    padding-top: calc(68px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    padding-top: calc(40px / vars.$medium * 100vw);
    grid-column: 1 / 2;
  }
  @media screen and (max-width: vars.$small) {
    padding-top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 1 / 3;
    & > svg {
      width: calc(80px / vars.$small * vars.$smallCoef * 100vw) !important;
    }
  }
}

.address {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  font-size: 18px;
  line-height: 28px;
  padding-top: 196px;
  @media screen and (max-width: vars.$big) {
    font-size: calc(18px / vars.$big * 100vw);
    line-height: calc(28px / vars.$big * 100vw);
    padding-top: calc(196px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(15px / vars.$medium * 100vw);
    line-height: calc(24px / vars.$medium * 100vw);
    padding-top: calc(40px / vars.$medium * 100vw);
    grid-column: 2 / 3;
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(15px / vars.$small * vars.$smallCoef * 100vw);
    line-height: calc(24px / vars.$small * vars.$smallCoef * 100vw);
    padding-top: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 1 / 3;
    grid-row: auto;
  }
}

.links {
  display: flex;
  grid-column: 4 / -1;
  grid-row: 1 / 2;
  gap: 90px;
  padding-top: 79px;
  @media screen and (max-width: vars.$big) {
    padding-top: calc(79px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    display: none;
  }
}

.link-block {
  font-size: 18px;
  line-height: 32px;
  & > a {
    display: block;
    transition: color 0.5s ease;
  }
  & > a:first-child {
    text-transform: uppercase;
    margin-bottom: 9px;
  }
  & > a:hover {
    color: vars.$blue;
  }

  @media screen and (max-width: vars.$big) {
    font-size: calc(18px / vars.$big * 100vw);
    line-height: calc(32px / vars.$big * 100vw);
    & > a:first-child {
      margin-bottom: calc(9px / vars.$big * 100vw);
    }
  }
}

.link-block:last-child {
  margin-left: 73px;
  & > a {
    text-transform: uppercase;
  }
  @media screen and (max-width: vars.$big) {
    margin-left: calc(73px / vars.$big * 100vw);
  }
}

.solutions-block {
  margin-right: 6px;
  @media screen and (max-width: vars.$big) {
    margin-right: calc(6px / vars.$big * 100vw);
  }
}

.top-list {
  grid-column: 4 / 8;
  display: flex;
  gap: 40px;
  padding-top: 60px;
  @media screen and (max-width: vars.$big) {
    gap: calc(40px / vars.$big * 100vw);
    padding-top: calc(60px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    grid-column: 3 / 5;
    padding-top: calc(49px / vars.$medium * 100vw);
    gap: calc(20px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    grid-column: 1 / 3;
    padding-top: calc(49px / vars.$small * vars.$smallCoef * 100vw);
    gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.top-member {
  & > a {
    display: block;
  }
  @extend .T2;
  & > img {
    width: 110px;
    height: 36px;
    object-fit: contain;
    object-position: top left;
    margin-bottom: 15px;
  }
  @media screen and (max-width: vars.$big) {
    & > img {
      width: calc(110px / vars.$big * 100vw);
      height: calc(36px / vars.$big * 100vw);
      margin-bottom: calc(15px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    max-width: calc(115px / vars.$medium * 100vw);
    & > img {
      width: calc(110px / vars.$medium * 100vw);
      height: calc(36px / vars.$medium * 100vw);
      margin-bottom: calc(14px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    max-width: calc(110px / vars.$small * vars.$smallCoef * 100vw);
    & > img {
      width: calc(110px / vars.$small * vars.$smallCoef * 100vw);
      height: calc(36px / vars.$small * vars.$smallCoef * 100vw);
      margin-bottom: calc(14px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.link {
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    color: vars.$blue;
  }
}

.second-line-wrapper {
  color: vars.$white;
  background: vars.$black;
  padding-top: 16px;
  padding-bottom: 16px;
  @extend .T2;
  & a {
    @extend .link;
  }
  & span {
    &:nth-child(1) {
      grid-column: 1 / 3;
    }
    &:nth-child(2) {
      display: flex;
      gap: 50px;
      grid-column: 4 / 8;
    }
    &:nth-child(3) {
      justify-self: end;
      grid-column: 8 / 13;
    }
  }
  @media screen and (max-width: vars.$big) {
    padding-top: calc(16px / vars.$big * 100vw);
    padding-bottom: calc(16px / vars.$big * 100vw);
    & span:nth-child(2) {
      gap: calc(50px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    padding-top: calc(10px / vars.$medium * 100vw);
    padding-bottom: calc(10px / vars.$medium * 100vw);
    & span {
      &:nth-child(1) {
        grid-column: 1 / 2;
      }
      &:nth-child(2) {
        gap: calc(50px / vars.$medium * 100vw);
        grid-column: 2 / 4;
      }
      &:nth-child(3) {
        grid-column: 4 / 5;
      }
    }
  }
  @media screen and (max-width: vars.$small) {
    padding-top: calc(12px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(12px / vars.$small * vars.$smallCoef * 100vw);
    & span {
      &:nth-child(1) {
        grid-column: 1 / 2;
      }
      &:nth-child(2) {
        width: 100%;
        justify-content: space-between;
        grid-column: 1 / -1;
      }
      &:nth-child(3) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    }
  }
}
