@use "../../utils/variables" as vars;

.tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2000;
  height: 100vh;
  background: rgba($color: vars.$black, $alpha: 0.8);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 3000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: vars.$blue2;
  border-radius: 30px;
  padding: 40px 128px;
  text-align: center;
  & h3 {
    color: vars.$blue;
  }
  & > span {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: vars.$big) {
    border-radius: calc(30px / vars.$big * 100vw);
    padding: calc(40px / vars.$big * 100vw) calc(128px / vars.$big * 100vw);
    & > span {
      margin-top: calc(8px / vars.$big * 100vw);
      margin-bottom: calc(24px / vars.$big * 100vw);
    }
  }

  @media screen and (max-width: vars.$medium) {
    border-radius: calc(30px / vars.$medium * 100vw);
    padding: calc(20px / vars.$medium * 100vw) calc(88px / vars.$medium * 100vw);
    & > span {
      margin-top: calc(8px / vars.$medium * 100vw);
      margin-bottom: calc(24px / vars.$medium * 100vw);
    }
  }

  @media screen and (max-width: vars.$small) {
    border-radius: calc(30px / vars.$small * vars.$smallCoef * 100vw);
    width: 90%;
    padding: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    & > span {
      margin-top: calc(8px / vars.$small * vars.$smallCoef * 100vw);
      margin-bottom: calc(24px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.check {
  width: 80px;
  height: 80px;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: vars.$big) {
    width: calc(80px / vars.$big * 100vw);
    height: calc(80px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    width: calc(70px / vars.$medium * 100vw);
    height: calc(70px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    width: calc(60px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(60px / vars.$small * vars.$smallCoef * 100vw);
  }
}
