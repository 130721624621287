@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.container {
  background: vars.$black;
  border-radius: 30px;
  padding-top: 80px;
  padding-bottom: 100px;
  margin: 160px 0;
  @media screen and (max-width: vars.$big) {
    border-radius: calc(30px / vars.$big * 100vw);
    padding-top: calc(80px / vars.$big * 100vw);
    padding-bottom: calc(100px / vars.$big * 100vw);
    margin: calc(160px / vars.$big * 100vw) 0;
  }
  @media screen and (max-width: vars.$medium) {
    border-radius: calc(20px / vars.$medium * 100vw);
    padding-top: calc(80px / vars.$medium * 100vw);
    padding-bottom: calc(74px / vars.$medium * 100vw);
    margin: calc(120px / vars.$medium * 100vw) 0;
  }
  @media screen and (max-width: vars.$small) {
    border-radius: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-top: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    padding-bottom: calc(60px / vars.$small * vars.$smallCoef * 100vw);
    margin: calc(80px / vars.$small * vars.$smallCoef * 100vw) 0;
    padding-left: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-right: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    row-gap: calc(40px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  grid-column: 1 / 6;
  color: vars.$blue2;
  text-transform: uppercase;
  margin-left: 100px;
  & h3 {
    @extend .H3;
  }
  & > h3:nth-child(2) {
    max-width: 460px;
  }
  @media screen and (max-width: vars.$big) {
    gap: calc(30px / vars.$big * 100vw);
    margin-left: calc(100px / vars.$big * 100vw);
    & > h3:nth-child(2) {
      max-width: calc(460px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    gap: calc(20px / vars.$medium * 100vw);
    margin-left: calc(60px / vars.$medium * 100vw);
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    & > h3:nth-child(2) {
      max-width: calc(392px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
    margin-left: 0;
    grid-column: 1 / 3;
    grid-row: auto;
    width: 100%;
    & > h3:nth-child(2) {
      max-width: 100%;
    }
  }
}

.title {
  color: vars.$blue;
  position: relative;
  display: inline !important;
  &::after {
    content: "";
    position: absolute;
    left: 204px;
    top: 48%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: currentColor;
    @media screen and (max-width: vars.$big) {
      left: calc(204px / vars.$big * 100vw);
      width: calc(25px / vars.$big * 100vw);
      height: calc(25px / vars.$big * 100vw);
    }
    @media screen and (max-width: vars.$medium) {
      left: calc(155px / vars.$medium * 100vw);
      width: calc(16px / vars.$medium * 100vw);
      height: calc(16px / vars.$medium * 100vw);
    }
    @media screen and (max-width: vars.$small) {
      left: calc(140px / vars.$small * vars.$smallCoef * 100vw);
      width: calc(16px / vars.$small * vars.$smallCoef * 100vw);
      height: calc(16px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 20px;
  grid-column: 6 / 13;
  width: 100%;
  justify-self: end;
  align-items: start;
  max-width: 764px;
  margin-right: 137px;
  @media screen and (max-width: vars.$big) {
    column-gap: calc(32px / vars.$big * 100vw);
    row-gap: calc(20px / vars.$big * 100vw);
    max-width: calc(764px / vars.$big * 100vw);
    margin-right: calc(137px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    column-gap: calc(19px / vars.$medium * 100vw);
    row-gap: calc(20px / vars.$medium * 100vw);
    max-width: calc(395px / vars.$medium * 100vw);
    margin-right: calc(56px / vars.$medium * 100vw);
    grid-column: 3 / 5;
    grid-row: 1 / 2;
  }
  @media screen and (max-width: vars.$small) {
    column-gap: calc(19px / vars.$small * vars.$smallCoef * 100vw);
    row-gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
    margin-right: 0;
    grid-column: 1 / 3;
    grid-row: auto;
    grid-template-columns: 1fr;
    max-width: none;
  }
}

.name,
.company,
.phone,
.email,
.comment,
.checkbox,
.btn {
  @media screen and (max-width: vars.$small) {
    grid-column: 1 / -1 !important;
  }
}

.name {
  grid-column: 1 / -1;
}

.company {
  grid-column: 1 / 2;
  @media screen and (max-width: vars.$small) {
    margin-bottom: calc(
      10px / vars.$small * vars.$smallCoef * 100vw
    ) !important;
  }
}

.phone {
  grid-column: 2 / 3;
}

.email {
  grid-column: 1 / -1;
}

.comment {
  grid-column: 1 / -1;
}

.btn {
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  justify-self: end;
  @media screen and (max-width: vars.$medium) {
    margin-right: calc(-10px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    grid-row: auto;
    margin-right: 0;
    margin-top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    justify-self: start;
  }
}

.checkbox {
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  & > p {
    max-width: 345px;
  }
  @media screen and (max-width: vars.$big) {
    & > p {
      max-width: calc(345px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    & > p {
      max-width: calc(184px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    grid-row: auto;
    & > p {
      max-width: calc(230px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.error__container {
  color: vars.$error_color;
}
