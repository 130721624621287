@use "../../utils/variables" as vars;

.container {
  display: none;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: calc(((100vw - 1920px) / 2) + (1920px - 1135px) / 2);
  bottom: 0;
  z-index: 3000;
  width: 60vw;
  max-width: 1135px;
  padding: 22px 70px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: vars.$blue2;
  box-shadow: 0 -2px 4px rgba($color: vars.$black, $alpha: 0.2);
  & a:first-child {
    color: vars.$blue;
  }
  @media screen and (max-width: vars.$big) {
    max-width: calc(1135px / vars.$big * 100vw);
    padding: calc(22px / vars.$big * 100vw) calc(70px / vars.$big * 100vw);
    border-top-left-radius: calc(30px / vars.$big * 100vw);
    border-top-right-radius: calc(30px / vars.$big * 100vw);
    left: 20vw;
  }

  @media screen and (max-width: vars.$medium) {
    max-width: none;
    border-top-left-radius: calc(30px / vars.$medium * 100vw);
    border-top-right-radius: calc(30px / vars.$medium * 100vw);
    width: 80vw;
    left: 10vw;
    gap: calc(10px / vars.$medium * 100vw);
    padding-top: calc(20px / vars.$medium * 100vw);
    padding-left: calc(30px / vars.$medium * 100vw);
    padding-right: calc(30px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    border-top-left-radius: calc(30px / vars.$small * vars.$smallCoef * 100vw);
    border-top-right-radius: calc(30px / vars.$small * vars.$smallCoef * 100vw);
    gap: calc(10px / vars.$small * vars.$smallCoef * 100vw);
    padding: calc(20px / vars.$small * vars.$smallCoef * 100vw)
      calc(30px / vars.$small * vars.$smallCoef * 100vw);
    flex-direction: column;
    text-align: center;
    width: 100vw;
    left: 0;
  }
}
