@use "../../utils/variables" as vars;

.container {
  display: none;
  position: fixed;
  bottom: 40px;
  right: calc((100vw - vars.$big) / 2 - 80px);
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background: vars.$white;
  box-shadow: 2px 5px 10px 0px #a6abbd80;
  z-index: 2000;
  color: vars.$blue;
  cursor: pointer;
  transition: background 0.5s ease;
  &:hover {
    color: vars.$white;
    background: vars.$blue;
  }
  & > svg {
    width: 34%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: vars.$big) {
    bottom: calc(40px / vars.$big * 100vw);
    right: calc(40px / vars.$big * 100vw);
    width: calc(104px / vars.$big * 100vw);
    height: calc(104px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    bottom: calc(40px / vars.$medium * 100vw);
    right: calc(40px / vars.$medium * 100vw);
    width: calc(74px / vars.$medium * 100vw);
    height: calc(74px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    display: none !important;
  }
}
