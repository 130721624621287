@use "../../utils/variables" as vars;

.container {
  overflow: hidden;
  position: relative;
}

.content {
  background: vars.$white;
  width: 100%;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: vars.$small) {
    padding-top: 44px;
  }
}
