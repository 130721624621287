@use "../../utils/variables" as vars;

.container {
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: vars.$big) {
    padding: 0 calc(100px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    padding: 0 calc(40px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    padding: 0 calc(20px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.limit {
  max-width: vars.$big;
  display: flex;
  flex-direction: column;
  width: 100%;
}
