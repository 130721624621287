@use "../../utils/variables" as vars;

.container {
  color: rgba($color: vars.$black, $alpha: 0.4);
  margin-top: 45px;
  .link {
    position: relative;
    margin-right: 50px;
    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(100% + 10px);
        width: 30px;
        height: 1px;
        background-color: currentColor;
      }
    }
  }
  @media screen and (max-width: vars.$big) {
    margin-top: calc(45px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: calc(25px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    margin-top: calc(45px / vars.$small * vars.$smallCoef * 100vw);
  }
}
